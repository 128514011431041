<template>
	<div class="max-w-screen-lg mx-auto w-full">
		
	<!-- <div class="max-w-screen-lg mx-auto"> -->
		<!-- <div class="flex-none flex gap-4 px-8 pt-8 pb-4"> -->
		<div class="flex gap-4"> 
			<a 
				class="flex-1 text-center rounded-lg px-3 py-1 border border-beige-light" 
				:class="activePreset == 'week' ? 'bg-gray-darker text-white' : 'bg-beige'"
				@click="onSetPreset('week')">
				
				Uke
			</a>
			<a 
				class="flex-1 text-center rounded-lg px-3 py-1 border border-beige-light" 
				:class="activePreset == 'month' ? 'bg-gray-darker text-white' : 'bg-beige'"
				@click="onSetPreset('month')">
				
				Måned
			</a>
			<a 
				class="flex-1 text-center rounded-lg px-3 py-1 border border-beige-light" 
				:class="activePreset == 'year' ? 'bg-gray-darker text-white' : 'bg-beige'"
				@click="onSetPreset('year')">
				
				År
			</a>
			<a 
				class="flex-1 text-center rounded-lg px-3 py-1 border border-beige-light" 
				:class="activePreset == 'custom' ? 'bg-gray-darker text-white' : 'bg-beige'"
				@click="onSetPreset('custom')">
				
				Egendefinert
			</a>
		</div>
		<div 
			class="border border-beige-dark rounded-lg mt-4 p-4"
			v-if="activePreset == 'custom'">
				
			<div class="text-center">
				<a class="link block p-2" v-if="showCalendar" @click="showCalendar = !showCalendar;">Skjul kalender</a>
				<a class="link block p-2" v-else @click="showCalendar = !showCalendar;">Vis kalender</a>
			</div>
			
			<template v-if="showCalendar">
				<DatePicker 
					range 
					v-model="dateRange" 
					:clearable="false"
					:open="true"
					:inline="true"
					class="cursor-pointer" 
					:value-type="'timestamp'"
					:lang="{formatLocale: {firstDayOfWeek: 1}}"
				/>
			</template>
		</div>
		<div 
			v-if="inAdmin"
			class="p-4 flex space-x-4 items-center border border-beige-dark rounded-lg mt-4">
			
			<div class="flex-grow">Toppliste-plasseringer:</div>
			<select 
				v-model="leaderboardPositions"
				class="flex-none">
				
				<option :value="null" selected>Ikke overstyr</option>
				<option 
					v-for="count in 100"
					:key="'option_'+count"
					:value="count" 
				>{{ count }}</option>
			</select>
		</div>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
	components: {
		DatePicker,
	},
	
	computed: {
		inAdmin(){
			if (this.$route && this.$route.path.startsWith('/admin/') ) {
				return true;
			}
			return false;
		},
	},
	
	data(){
		return {
			from: null,
            to: null,
            activePreset: 'week',
            loading: null,
            dateRange: null,
			stats: null,
			leaderboardPositions: null,
			showCalendar: true,
		}
	},
	
    watch: {
        dateRange: function (newValue, oldValue) {
            this.from = newValue[0];
            let to = this.$moment( newValue[1] ).endOf('day').valueOf();
            this.to = to;
			
            if (oldValue) {
				this.onFetchStatsHistoric();
			}
        },
		
		leaderboardPositions: function (newValue, oldValue) {
            this.onFetchStatsHistoric();
        },
    },
    
    methods: {
        async onSetPreset(preset){
            switch ( preset ) {
                case 'week':
                    // this.from = this.$moment.utc().startOf('day').subtract(1, 'W').valueOf(); 
                    // this.to = this.$moment.utc().endOf('day').valueOf();
                    this.from = this.$moment().startOf('week');
                    this.to = this.$moment().endOf('week');
                    break
                    
                case 'month':
                    // this.from = this.$moment.utc().startOf('day').subtract(1, 'M').valueOf(); 
                    // this.to = this.$moment.utc().endOf('day').valueOf();
                    this.from = this.$moment().startOf('month');
                    this.to = this.$moment().endOf('month');
                    break
                    
                case 'year':
                    // this.from = this.$moment.utc().startOf('day').subtract(1, 'Y').valueOf(); 
                    // this.to = this.$moment.utc().endOf('day').valueOf();
                    this.from = this.$moment().startOf('year');
                    this.to = this.$moment().endOf('year');
                    break
                    
                case 'custom':
                    // this.from = this.$moment().startOf('week');
                    // this.to = this.$moment().endOf('week');
                    let from = this.$moment().startOf('week').valueOf();
                    let to = this.$moment().endOf('week').valueOf();
					this.showCalendar = true;
                    
                    this.dateRange = [
                        from,
                        to,
                    ];
                    
                    console.log('this.dateRange', this.dateRange);
                    break
            }
            
            this.activePreset = preset;
			
            if (preset != 'custom') {
                this.from = this.from.valueOf();
                this.to = this.to.valueOf();
				
				this.onFetchStatsHistoric();
            }
        },
        
        async onFetchStatsHistoric(){
            try {
				
				console.log('onFetchStatsHistoric called!');
				
                this.loading = 'fetching';
				this.$emit('loading', this.loading);
                
                let params = {
                    from: this.from,
                    to: this.to,
					leaderboardPositions: this.leaderboardPositions,
                };
                
				console.log('params', params);
				
				let stats;
				
				if (this.$route && this.$route.path.startsWith('/portal/') ) {
					const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT + '/portal/stats?from='+params.from+'&to='+params.to+'&leaderboardPositions='+params.leaderboardPositions, {
						headers: {
							authorization: this.account.accessToken,
						},
					});
					
					stats = response.data;
                }
				else {
					stats = await this.$store.dispatch('listStatsHistoric', params );
				}
				
				this.$emit('fetchedStatsHistoric', stats);
				this.showCalendar = false;
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    text: e.message || 'Kunne ikke hente statistikk'
                });
                
                console.error(e);
            } 
            finally {
                this.loading = null;
				this.$emit('loading', this.loading);
            }
        },
    },
    
    mounted(){
        this.onSetPreset( this.activePreset );
    },
}
</script>